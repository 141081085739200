import { ApolloError, NetworkStatus } from '@apollo/client'
import { useCallback } from 'react'

import { usePostsConnectionQuery, PostItemFragment } from '~/src/generated/graphql'
import { nonNullable } from '~/src/utils'

interface PostConnectionParams {
	where: any
	start?: number
	limit?: number
	skip?: boolean
}

interface PostConnectionReturn {
	posts: PostItemFragment[] 
	totalCount: number
	hasNextPage: boolean
	networkStatus: NetworkStatus 
	fetchMore: () => any
	loading: boolean
	error?: ApolloError
}

export function usePostConnection({ where, start = 0, limit = 9, skip }: PostConnectionParams): PostConnectionReturn {

	const { data, loading, error, fetchMore: _fetchMore, networkStatus } = usePostsConnectionQuery({
		variables: {
			where,
			start,
			limit
		},
		skip
	})

	const posts = data?.postsConnection?.values?.filter(nonNullable) ?? []
	const totalCount = data?.postsConnection?.aggregate?.count ?? 0

	const page = Math.ceil(posts.length / limit)
	const hasNextPage = totalCount > posts.length

	const fetchMore = useCallback(async () => {
		if (!hasNextPage || !_fetchMore) return
		await _fetchMore({
			variables: {
				start: page * limit,
			}
		})
	}, [_fetchMore, page, hasNextPage, limit]) 


	return {
		posts,
		totalCount,
		fetchMore,
		networkStatus,
		hasNextPage,
		loading,
		error
	}
}