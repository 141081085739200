import { MdKeyboardArrowRight } from 'react-icons/md'

import { Button, Anchor, Image } from '~/src/components/shared'
import { FestivalItem as FestivalItemType } from '~/src/types'
import { getFestivalDate } from '~/src/utils/festival'
import { Container } from '~/src/components/shared'

import styles from './FestivalItem.module.scss'

interface Props {
	data: FestivalItemType
}

export function FestivalItem({ data }: Props) {
  const { id, title, startDate: _startDate, endDate: _endDate, place, coverImage } = data || {}
  const startDate = new Date(_startDate)
  const endDate = new Date(_endDate)

  const dateText = getFestivalDate(startDate, endDate) 

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.header}>{title}</h1>
      {startDate && <h3 className={styles.date}>{dateText}</h3>}
      {place && <p className={styles.place}>{place}</p>}
      <Anchor href={`/festivals/${id}`}>
        <Button
          variant="primary"
          size="lg"
          className={styles.more}
          endIcon={<MdKeyboardArrowRight className="text-2xl inline-block"/>}>
          <span className="align-bottom">MORE INFO</span> 
        </Button>
      </Anchor>

      {
        coverImage?.url && (
        <Container>
          <div className={styles.bannerWrapper}>
            <Image
              loading="eager"
              src={coverImage?.url}
              formats={coverImage?.formats}
              layout="responsive"
              width={16}
              height={9}
            />
          </div>
        </Container>
        )
      }
    </div>
  )
}