
import AspectRatio from 'react-aspect-ratio'
import { useFestivalContext } from '~/src/contexts'
import { Anchor, Image } from '~/src/components/shared'
import { nonNullable } from '~/src/utils'

export function FestivalLineup() {
  const { data, loading, error } = useFestivalContext()
  const { lineup } = data || {}
  const items = lineup?.filter(nonNullable) ?? []

  if (loading || error) return null

  return (
    <div className="max-w-screen-xl mx-auto px-4">
      {
        items.map(({ title, banners }, index) => {
          const bannerItems = banners?.filter(nonNullable) ?? [] 

          return (
            <div
              className="my-20"
              key={index}>
              <h1 className="text-3xl text-center font-bold text-gray-800 mb-10">{title}</h1>
              {
                bannerItems.length > 0 && (
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                    {
                      bannerItems.map((banner, index) => {
                        const { image, description, link, title: bannerTitle } = banner || {}
                        if(!image?.url) return null

                        return (
                          <Anchor
                            className="bg-white inline-block p-2"
                            href={link}
                            key={`${banner.link}-${index}`}>
                            <AspectRatio ratio="1/1">
                              <img 
                                src={image?.formats?.medium?.url}
                                className="object-cover w-full h-full"
                              />
                            </AspectRatio>
                            {bannerTitle && (<h1 className="text-lg text-primary-light">{bannerTitle}</h1>)}
                            {description && (<p className="text-md text-gray-700">{description}</p>)}
                          </Anchor>
                        )
                      })
                    }
                  </div>
                )
              }
            </div>
          )
        })
      }
    </div>
  )
}