export const MENUS = [
  {
    id: 'mintplus',
    label: 'Mint+',
    path: '/mintplus',
  },
  {
    id: 'festival',
    label: 'FESTIVAL',
    path: '/festivals',
  },
  {
    id: 'connect',
    label: 'CONNECT',
    path: '/connect',
  },
  {
    id: 'originals',
    label: 'ORIGINALS',
    path: '/originals',
  },
  {
    id: 'shop',
    label: 'SHOP',
    path: '/shop',
  },
]
