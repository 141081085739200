import { useRouter } from 'next/router'
import { SectionHeader, Button, SectionHeaderProps, Container } from '~/src/components/shared'
import { PostGrid, PostCoverType } from '~/src/components/post'
import { usePostsQuery, ComponentPageDisplayOption } from '~/src/generated/graphql'
import { nonNullable, buildPostQuery } from '~/src/utils'
import { Category } from '~/src/types'

interface Props {
  category: Category;
  coverType: PostCoverType 
  headerProps?: Partial<SectionHeaderProps>
  showMore?: boolean
  displayOption?: ComponentPageDisplayOption 
}

const DEFAULT_LIMIT = 3
export function CategoryPosts({ category, coverType, showMore, headerProps, displayOption }: Props) {
  const router = useRouter()
  const limit = displayOption?.categoryLimit || DEFAULT_LIMIT
  const { data, loading, error } = usePostsQuery({
    variables: {
      where: buildPostQuery({ categoryId: category.categoryId }),
      start: 0,
      limit: limit,
    },
    skip: !Boolean(category?.categoryId),
  })

  const posts = data?.posts?.filter(nonNullable) ?? []

  if (loading || error) return null
  if (!(posts?.length > 0)) return null

  return (
    <>
      <SectionHeader
        title={category.name}
        description={category.description ?? ''}
        variant='default'
        className='mt-8 lg:mt-24 mb-8'
        {...headerProps}
      />
      {
        showMore && (
          <p className='text-center mb-8'>
            <Button
              label='SEE MORE'
              variant='primary'
              size='md'
              onClick={() => router.push(`/categories/${category.categoryId}`)}/>
          </p>
        )
      }
      <Container>
        <PostGrid
          posts={posts}
          coverType={coverType}
          displayOption={displayOption} />
      </Container>
    </>
  )
}
