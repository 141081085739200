import range from 'lodash/range'
import cx from 'classnames'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

import styles from './Pagination.module.scss'


interface Props {
	className?: string
	currentPage: number
	hasNextPage: boolean
	hasPrevPage: boolean
	onPrev: () => void
	onNext: () => void
	goTo: (page: number) => void
	renderItem?: (page: number) => JSX.Element
	startIndex: number
	endIndex: number
}

export function Pagination({ className, currentPage, onPrev, onNext, goTo, startIndex, endIndex, hasNextPage, hasPrevPage, renderItem }: Props) {

  return (
    <div className={cx(className, styles.wrapper)}>
      <span
        className={cx(styles.prev, { [styles.disable]: !hasPrevPage })}
        onClick={onPrev}><MdKeyboardArrowLeft /></span>
      {range(startIndex, endIndex).map(idx => (
        <span
          className={cx(styles.item, { "text-info-light": currentPage === idx })}
          key={idx}>
          {
            renderItem ? renderItem(idx): (
              <span onClick={()=> goTo(idx)}>{idx}</span>
            )
          }
        </span>
      ))}	
      <span
        className={cx(styles.next, { [styles.disable]: !hasNextPage })}
        onClick={onNext}><MdKeyboardArrowRight /></span>
    </div>
  )
}