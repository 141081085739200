import React, { ReactNode } from 'react'

import { Header } from '~/src/components/shared/Header'
import { Footer } from '~/src/components/shared/Footer'

import styles from './BaseLayout.module.scss'

interface Props {
  children: ReactNode;
}

export function BaseLayout({ children }: Props) {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  )
}
