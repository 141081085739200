import { format, isSameDay, isSameMonth } from 'date-fns'

const FULL_DATE_FORMAT = 'yyyy.MM.dd.EEE'

export const getEndDateFormat = (startDate: Date, endDate: Date) => {
  if (isSameDay(startDate, endDate)) return 'hh:mm aa'
  if (isSameMonth(startDate, endDate)) return 'dd.EEE'
  return FULL_DATE_FORMAT 
}

export const getFestivalDate = (startDate: Date, endDate: Date) => {
  try {
    return [format(startDate, FULL_DATE_FORMAT), format(endDate, getEndDateFormat(startDate, endDate))].join(' - ')
  } catch (err) {
    return ''
  }
}