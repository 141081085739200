import { PostCard, PostCoverType } from '~/src/components/post'
import { PostItemFragment, ComponentPageDisplayOption } from '~/src/generated/graphql'

interface Props {
	posts: PostItemFragment[]
	coverType: PostCoverType
	displayOption?: ComponentPageDisplayOption 
}

export function PostGrid({ posts, coverType, displayOption }: Props) {
  const { pc = 3, mobile = 2 } = displayOption || {}

  return (
    <div className={`grid grid-cols-${mobile} lg:grid-cols-${pc} gap-3`}>
      {posts.map((post) => (
        <div
          key={post.id}
          className="mb-10">
          <PostCard
            post={post}
            coverType={coverType} />
        </div>
      ))}
    </div>
  )
}