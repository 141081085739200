import { useRouter } from 'next/router'

import { FestivalBanner } from '~/src/components/festival'
import { PostTable } from '~/src/components/post'
import { FestivalPosition } from '~/src/generated/graphql'
import { useFestivalContext } from '~/src/contexts'
import { FESTIVAL_NOTICE_CATEGORY } from '~/src/const'
import { CategoryTitle } from '~/src/components/shared'

import { generatePath } from '~/src/utils'


export function FestivalNotice() {
  const router = useRouter()
  const { data: festivalData } = useFestivalContext()
  const { id } = festivalData || {}

  const handlePageChange = (page: number) => {
    router.push({ 
      pathname: generatePath(router.pathname, { festivalId: id as string }),
      query: { page }
    }, undefined, { shallow: true })
  }

  return (
    <div className="mt-4 md:mt-0">
      <FestivalBanner position={FestivalPosition.Notice} />
      <CategoryTitle categoryId={FESTIVAL_NOTICE_CATEGORY} />
      <div className='max-w-screen-xl mx-auto px-4'>
        <PostTable 
          festivalId={id}
          categoryId={FESTIVAL_NOTICE_CATEGORY}
          generateLink={(post) => `${generatePath(router.pathname, { festivalId: id as string })}/${post.id}`}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}