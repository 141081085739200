import { useFestivalContext } from '~/src/contexts'

interface Props {
	header: string
	description?: string | null
}

export function PageTitle({ header, description }: Props) {
  const { data } = useFestivalContext() 

  const title = data?.title

  if (!title) return null

  return (
    <div className="my-32 font-bold text-center">
      <h1 className="text-primary-light text-3xl font-poppins">{title}</h1>
      <p className="text-primary-light text-5xl my-6 font-poppins">{header}</p>
      {
        description && (
          <span className="text-gray-700 text-3xl">{description}</span>
        )
      }
    </div>
  )
}