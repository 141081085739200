
import { PageTitle } from '~/src/components/festival'
import { useCategoriesContext } from '~/src/contexts/CategoriesContext'

interface Props {
	categoryId: string 
}
export function CategoryTitle({ categoryId }: Props) {

  const { categories }= useCategoriesContext()
  const titleCategory = categories.find(category => category?.categoryId === categoryId)

  const { name, description } = titleCategory || {}

  if(!name) return null

  return (
    <PageTitle 
      header={name}
      description={description}
    />
  )
}