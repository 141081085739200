
interface Props {
	children: any
}

export function Container({ children }: Props) {
  return(
    <div className='container mx-auto px-4'>
      {children}
    </div>
  )
}