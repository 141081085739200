import { useRouter } from 'next/router'
import { format } from 'date-fns'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

import { DEFAULT_DATE_FORMAT } from '~/src/const'
import { usePostQuery, usePostPrevNextQuery } from '~/src/generated/graphql'
import { PostContent } from '~/src/components/post/PostContent'
import { Button } from '~/src/components/shared'
import { nonNullable, buildPostPrevNextQuery } from '~/src/utils'

import styles from './NormalView.module.scss'

interface Props {
	listUrl: string
	generateLink: (postId: string) => string
	festivalId?: string
}

export function NormalView({ listUrl, generateLink, festivalId }: Props) {
  const router = useRouter()
  const { query } = router
  const postId = query?.postId as string

  const { data, loading, error } = usePostQuery({
    variables: {
      id: postId
    },
    skip: !postId
  })

  const { title, publishDate, content, category, order } = data?.post || {}

  const { data: relatedData } = usePostPrevNextQuery({
    variables: {
      ...buildPostPrevNextQuery({ festivalId, postId, categoryId: category?.categoryId, publishDate, order })
    },
    skip: !category?.categoryId || !publishDate
  })

  const { prev, next } = relatedData || {}
  const [prevPost] = prev?.values?.filter(nonNullable) || []
  const [nextPost] = next?.values?.filter(nonNullable) || []

  const prevId = prevPost?.id
  const nextId = nextPost?.id

  const handleNavigation = (id: string) => {
    router.push(generateLink(id))	
  }

  if (!postId || loading || error) return null

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>	
        <span className={styles.helpBlock}>{format(new Date(publishDate), DEFAULT_DATE_FORMAT)}</span>
      </div>
      {content && (
        <PostContent
          className="mb-20"
          content={content} />
      )}
      <div className={styles.buttons}>
        {prevId ? (
          <Button
            className={styles.left}
            variant="primary"
            size="md"
            onClick={() => handleNavigation(prevId)}><MdKeyboardArrowLeft size={24} /> PREV</Button> 
        ): <div />}
        <Button
          className="py-4"
          variant="primary"
          size="md"
          onClick={() => router.push(listUrl)}>LIST</Button>
        {nextId ? (
          <Button
            className={styles.right}
            variant="primary"
            size="md"
            onClick={() => handleNavigation(nextId)}>NEXT <MdKeyboardArrowRight size={24} /></Button>
        ): <div />}
      </div>
    </div>
  )
}