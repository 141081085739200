import { AiOutlineLoading3Quarters } from 'react-icons/ai'

interface Props {
	size?: number
}

export function Loading({ size }: Props) {
  return (
    <AiOutlineLoading3Quarters
      size={size}
      className="text-primary-light animate-spin" />
  )
}