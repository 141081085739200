export const MINT_PLUS_ROOT_CATEGORY = '10000'
export const ORIGINALS_ROOT_CATEGORY = '12000'

export const FESTIVAL_NOTICE_CATEGORY = '11001'
export const FESTIVAL_CONTENTS_CATEGORY = '11002'
export const FESTIVAL_LINEUP_CATEGORY = '11003'
export const FESTIVAL_EXTRAS_CATEGORY = '11004'

export const CONNECT_AVENUE_CATEGORY = '13001'
export const CONNECT_FOUND_CATEGORY = '13002'
export const CONNECT_JOBINFO_CATEGORY = '13003'
