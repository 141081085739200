import React from 'react'

import { Anchor } from '~/src/components/shared/Anchor'

interface Props {
  height?: number
}

export function Logo({ height = 40 }: Props) {
  return (
    <Anchor
      href='/'
      className='cursor-pointer'>
      <img
        src='/images/logo.png'
        style={{ height }} />
    </Anchor>
  )
}
