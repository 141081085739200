import { useRef, useState } from 'react'
import { Anchor } from '~/src/components/shared'
import { useClickOutside } from '~/src/hooks'

import styles from './FamilySites.module.scss'

export function FamilySites() {
  const [isOpen, setIsOpen] = useState<boolean>()
  const ref = useRef<HTMLDivElement>(null)

  useClickOutside({ ref, handler: () => {
    setIsOpen(false)
  } })

  return (
    <div
      className={styles.wrapper}
      ref={ref}>
      <div className="cursor-pointer">
        <div onClick={() => setIsOpen(true)}>FAMILY SITES</div>
      </div>
      {
        isOpen && (
          <ul className={styles.dropdown}>
            <li><Anchor href="https://mpmg.co.kr">MPMG</Anchor></li>
            <li><Anchor href="https://mpmgmusic.com">MPMG MUSIC</Anchor></li>
            <li><Anchor href="https://www.instagram.com/thevault.co.kr">THE VAULT</Anchor></li>
            <li><Anchor href="https://instagram.com/78.avenue">78 AVENUE</Anchor></li>
            <li><Anchor href="https://www.instagram.com/cafe.riccardo">Cafe Riccardo</Anchor></li>
          </ul>
        )
      }
    </div>
  )
}