import { useCallback, ChangeEvent, FormEvent, useState } from 'react'
import { useRouter } from 'next/router'

export function useSearchForm() {
  const router = useRouter()

  const query = router?.query?.query as string
  const [searchQuery, setSearchQuery] = useState<string>(query ?? '')

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }, [])

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    router.push({
      pathname: '/search',
      query: {
        query: searchQuery 
      }
    })
    e.preventDefault()
  }, [searchQuery, router])

  const handleClear = useCallback(() => {
    setSearchQuery('')
  }, [])

  return {
    searchQuery,
    onChange: handleChange,
    onSubmit: handleSubmit,
    onClear: handleClear
  }
}