import cx from 'classnames'
import { useTransition, animated, useChain, useSpringRef } from 'react-spring'
import { HiOutlineSearch } from 'react-icons/hi'
import { MdClose } from 'react-icons/md'

import { Anchor, Portal, Logo, SocialIcons } from '~/src/components/shared'
import { useCurrentMenu, useSearchForm } from '~/src/hooks'
import { MENUS } from '~/src/const'

import styles from './Drawer.module.scss'

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function Drawer({ isOpen, onClose }: Props) {
  const menu = useCurrentMenu()

  const slideRef = useSpringRef()
  const opacityRef = useSpringRef()
  const { searchQuery, onChange, onSubmit } = useSearchForm()

  const slideTransitions = useTransition(isOpen, {
    from: { transform: 'translate3d(-100%, 0, 0)' },
    enter: { transform: 'translate3d(0%, 0, 0)' },
    leave: { transform: 'translate3d(-100%, 0, 0)' },
    delay: 100,
    ref: slideRef,
  })

  const opacityTransitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 100,
    ref: opacityRef,
  })

  useChain(isOpen ? [opacityRef, slideRef] : [slideRef, opacityRef], [0, 0.1])

  return (
    <Portal>
      {opacityTransitions(
        (_styles, item) =>
          item && (
            <animated.div
              className={styles.backdrop}
              style={_styles}
              onClick={onClose}
            />
          ),
      )}
      {slideTransitions(
        (_styles, item) =>
          item && (
            <animated.div
              style={_styles}
              className={styles.drawer}>
              <div className='p-8 relative h-full'>
                <div className="flex justify-between items-center">
                  <Logo height={32} />
                  <MdClose size={28} color="white" onClick={onClose} />
                </div>
                <form
                  className={styles.searchForm}
                  onSubmit={onSubmit}>
                  <HiOutlineSearch
                    size={24}
                    className={styles.searchIcon}
                  />
                  <input
                    type='text'
                    value={searchQuery}
                    onChange={onChange}
                  />
                </form>
                <ul className="mt-8">
                  {MENUS.map(({ id, label, path }) => (
                    <li
                      key={id}
                      className={cx(styles.menuItem, {
                        [styles.active]: menu?.id === id,
                      })}>
                      <Anchor href={path}>{label}</Anchor>
                    </li>
                  ))}
                </ul>
                <div
                  className="m-8"
                  style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                  <SocialIcons />
                </div>
              </div>
            </animated.div>
          ),
      )}
    </Portal>
  )
}
