import { useMemo } from 'react'

import { usePageMetaContext } from '~/src/contexts'
import { nonNullable } from '~/src/utils'
import { PageBanner } from '~/src/components/shared'
import { Banner } from '~/src/types/banner'

export function PageMetaBanner() {
  const { data } = usePageMetaContext()

  const banners = data?.banners

  const items: Banner[] = useMemo(
    () =>
      (banners?.filter(nonNullable).filter((banner) => nonNullable(banner?.media)) ||
        []) as Banner[],
    [banners],
  )

  return (
    <PageBanner items={items} />
  )
}