import { ReactNode } from 'react'
import ReactDOM from 'react-dom'

interface Props {
  children: ReactNode;
}

export function Portal({ children }: Props) {
  if (typeof window !== 'object') return null
  const el = document.getElementById('modal-root')
  if (!el) return null
  return ReactDOM.createPortal(children, el)
}
