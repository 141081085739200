import cx from 'classnames'
import { useRouter } from 'next/router'

import { Anchor } from '~/src/components/shared'
import { generatePath } from '~/src/utils'

import styles from './FestivalNavigation.module.scss'

const MENUS = [
  {
    id: 'info',
    label: 'INFO'
  },
  {
    id: 'notice',
    label: 'NOTICE'
  },
  {
    id: 'contents',
    label: 'CONTENTS'
  },
  {
    id: 'lineup',
    label: 'LINE UP'
  },
  {
    id: 'extras',
    label: 'EXTRAS'
  }
]

const BASE_PATH = '/festivals/[festivalId]'

const getMenu = (pathname: string) => {
  const menu = pathname?.split(BASE_PATH)?.[1].slice(1)
  const postSlashIdx = menu.lastIndexOf("/")
	
  return postSlashIdx !== -1 ? menu.slice(0, postSlashIdx): menu
}

const getHref = (id: string, menu: string) => `${generatePath(BASE_PATH, { festivalId: id })}/${menu}`

export function FestivalNavigation() {
  const router = useRouter()
  const { pathname, query } = router

  const id = query?.festivalId as string
  const menu = getMenu(pathname) 

  const activeMenu = menu || 'info'

  if (!id) return null

  return (
    <nav className={styles.nav}>
      <div className="container mx-auto max-w-screen-lg">
        <ul className={styles.menu}>
          {
            MENUS.map(item => (
              <li
                key={item.id}
                className={cx({ [styles.active]: activeMenu === item.id }, 'cursor-pointer')}><Anchor href={getHref(id, item.id)}>{item.label}</Anchor></li>
            ))
          }
        </ul>
      </div>
    </nav>
  )
}