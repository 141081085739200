import { FestivalBanner } from '~/src/components/festival'
import { SectionHeader } from '~/src/components/shared'
import { PostContent } from '~/src/components/post/PostContent'
import { useFestivalContext } from '~/src/contexts'
import { FestivalPosition } from '~/src/generated/graphql'
import { getFestivalDate } from '~/src/utils/festival'

export function FestivalInfo() {
  const { data } = useFestivalContext()
  const { title, startDate: _startDate, endDate: _endDate, place, info } = data || {}

  const startDate = new Date(_startDate)
  const endDate = new Date(_endDate)

  const dateText = getFestivalDate(startDate, endDate) 

  if (!data) return null

  return (
    <div className="mt-4 md:mt-0">
      <FestivalBanner position={FestivalPosition.Info} />
      <div className='max-w-screen-xl mx-auto px-4'>
        <div className="grid grid-cols-3 gap-1 md:gap-2">
          <FestivalBanner position={FestivalPosition.InfoBanner_1} />
          <FestivalBanner position={FestivalPosition.InfoBanner_2} />
          <FestivalBanner position={FestivalPosition.InfoBanner_3} />
        </div>
        <div className="my-20">
          {title && <SectionHeader
            title={title}
            variant="primary"
            textAlign="left"
            className="mb-8"/>}
          <span className="text-primary-light text-2xl font-bold"><span className="uppercase">{dateText}</span> / {place}</span>
          {info && <PostContent
            content={info}
            className="my-20" />}
        </div>
      </div>
    </div>
  )
}