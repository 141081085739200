import { format } from 'date-fns'
import { MdDateRange } from 'react-icons/md'
import { HiHashtag } from 'react-icons/hi'

import { DEFAULT_DATE_FORMAT } from '~/src/const'
import { Anchor, Image } from '~/src/components/shared'
import { PostContent } from '~/src/components/post/PostContent'
import { PostCard, PostCardViewType } from '~/src/components/post/PostCard'
import { SectionHeader } from '~/src/components/shared'
import { nonNullable, isNotEmpty } from '~/src/utils'

import { PostDetail } from './types'

interface Props {
  post: PostDetail;
}

export function ProgramView({ post }: Props) {
  const { coverImage, title, content, publishDate, tags, related } = post || {}

  return (
    <article>
      <div className='overflow-hidden'>
        {/* TODO: default image */}
        <Image
          src={coverImage?.url ?? ''}
          formats={coverImage?.formats}
          layout='responsive'
          width={16}
          height={9}
          objectFit='cover'
          className='bg-gray-30'
        />
      </div>

      <div className='max-w-screen-xl mx-auto px-4'>
        <h1 className='mt-24 text-4xl font-bold'>{title}</h1>
        <PostContent
          className='my-8'
          content={content} />
        <div className='flex items-center mb-1 text-md'>
          <MdDateRange className='mr-1' />
          <span className='text-gray-700'>
            {format(new Date(publishDate), DEFAULT_DATE_FORMAT)}
          </span>
        </div>
        {isNotEmpty(tags) && (
          <div className='flex items-center text-gray-700'>
            <HiHashtag className='mr-1' />
            {tags &&
              tags.filter(nonNullable).map((tag, index) => (
                <Anchor
                  key={tag.id}
                  className='pr-2'
                  href={`/tags/${tag.slug}`}>
                  {tag.name}
                  {index !== tags.length - 1 ? ',' : ''}
                </Anchor>
              ))}
          </div>
        )}
        <div className="my-20">
          {isNotEmpty(related) && (
            <>
              <SectionHeader
                title='RELATED CONTENTS'
                variant='primary'
                textAlign='left'
                className='mb-16'
              />
              <div>
                {related
                  .filter((related) => nonNullable(related?.post))
                  .map((related) =>
                    related?.post ? (
                      <PostCard
                        key={related.post.id}
                        post={related.post}
                        className='mb-6'
                        viewType={PostCardViewType.Horizontal}
                      />
                    ) : null,
                  )}
              </div>
            </>
          )}
        </div>
      </div>
    </article>
  )
}
