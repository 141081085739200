import React from 'react'

import { Logo, Anchor, SocialIcons } from '~/src/components/shared'

import { FamilySites } from './FamilySites'

import styles from './Footer.module.scss'

export function Footer() {
  return (
    <footer className='bg-primary-dark py-8 px-4 lg:py-20'>
      <div className='container mx-auto'>
        <div className='flex flex-col w-full lg:mb-8 lg:flex-row lg:justify-between '>
          <div className='w-full flex justify-center lg:w-auto px-2 lg:px-0 '>
            <Logo />
          </div>
          <div className="flex justify-center">
            <SocialIcons />
          </div>
        </div>
        <div className={styles.sites}>
          <span className='pr-1 lg:pr-2'>
            <Anchor href="/about">
              ABOUT Mint Paper
            </Anchor>
          </span>
          <span className='px-1 lg:px-2'>|</span>
          <span className='px-1 lg:px-2'>
            <Anchor href="/contact">
              CONTACT US
            </Anchor>
          </span>
          <span className='px-1 lg:px-2'>|</span>
          <span className='pl-1 lg:pl-2'>
            <FamilySites />
          </span>
        </div>
        <div className={styles.copyright}>
          <span className='text-primary-text'>
            © 2021 MINT PAPER All Rights Reserved <br className='lg:hidden' />
            <span className='px-2 hidden lg:inline-block'>|</span> <Anchor href="/terms">이용약관</Anchor>{' '}
            <span className='px-2'>|</span> <Anchor href="/privacy">개인정보취급방침</Anchor>
          </span>
        </div>
      </div>
    </footer>
  )
}
