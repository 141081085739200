import AspectRatio from 'react-aspect-ratio'

interface Props {
  // youtube embed url
  url: string;
  ratio?: string;
}
export function YoutubeEmbed({ url, ratio = '16/9' }: Props) {
  return (
    <AspectRatio ratio={ratio}>
      <iframe
        src={url}
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </AspectRatio>
  )
}
