import { useRef, useEffect, useState } from 'react'

interface Props {
	fetchMore: () => any 
}

export function InfiniteScroll({ fetchMore }: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if(!ref.current || loading) return
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          setLoading(true)
          try {
            fetchMore().then(() => setTimeout(() => setLoading(false)))
          } catch(e) { }
        }
      })

    }, {
      root: null,
      rootMargin: '0px'
    })
    observer.observe(ref.current)

    return () => {
      if(!ref.current) return
      observer.unobserve(ref.current)
    }
  }, [fetchMore, loading])

	
  return (
    <div ref={ref} />
  )
}