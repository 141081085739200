import { Anchor } from '~/src/components/shared'

import FacebookIcon from '~/assets/icons/facebook.svg'
import InstagramIcon from '~/assets/icons/instagram.svg'
import YoutubeIcon from '~/assets/icons/youtube.svg'

import styles from './SocialIcons.module.scss'

export function SocialIcons() {

  return (
    <ul className={styles.socialList}>
      <li className={styles.socialItem}>
        <Anchor href="https://www.instagram.com/mintpaper_">
          <InstagramIcon className='w-10 h-10' />
        </Anchor>
      </li>
      <li className={styles.socialItem}>
        <Anchor href="https://www.youtube.com/user/MINTPAPERM">
          <YoutubeIcon className='w-10 h-10' />
        </Anchor>
      </li>
      <li className={styles.socialItem}>
        <Anchor href="https://www.facebook.com/mintBook">
          <FacebookIcon className='w-10 h-10' />
        </Anchor>
      </li>
    </ul>
  )
}