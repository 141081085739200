import { HiOutlineSearch } from 'react-icons/hi'
import { AiFillCloseCircle } from 'react-icons/ai'

import { showSearchLayerVar } from '~/src/libs/localCache'
import { useSearchForm } from '~/src/hooks/useSearchForm'

import styles from './SearchForm.module.scss'

export function SearchForm() {

  const { onChange, onSubmit, onClear, searchQuery } = useSearchForm()

  return (
    <form
      className={styles.inputWrapper}
      onSubmit={(e) => {
        onSubmit(e)
        showSearchLayerVar(false)
      }}>
      <HiOutlineSearch
        className={styles.searchIcon}
        size={30} />
      <input 
        type="text" 
        className={styles.input} 
        placeholder="검색어를 입력해주세요" 
        onChange={onChange}
        value={searchQuery}
        autoFocus
      />
      {searchQuery && (
        <AiFillCloseCircle
          className={styles.clearIcon}
          size={30}
          onClick={onClear} />
      )}
    </form>
  )
}