import NextImage, { ImageProps as NextImageProps, ImageLoaderProps } from 'next/image'

type ImageFormat = {
	ext: string
	url: string
	hash: string
	mime: string
	path: string
	size: number
	width: number
	height: number
}

type FormatDict = {
	[key: string]: ImageFormat
}

type Props = {
	formats?: FormatDict
} & NextImageProps

const THUMBNAIL_FORMAT_MAP: { [key: number]: string } = {
  2048: '3xlarge',
  1920: '2xlarge',
  1200: 'xlarge',
  1000: 'large',
  750: 'medium',
  500: 'small'
}

const createLoader = (formats: FormatDict) => ({ src, width, quality }: ImageLoaderProps) => {
  const format = formats[THUMBNAIL_FORMAT_MAP[width]]
	
  return format ? format.url: `/_next/image?url=${src}&w=${width}&q=75`
}

export function Image({ formats, ...rest }: Props) {

  return (
    <NextImage
      {...rest} 
      {...(formats && { loader: createLoader(formats) })}
    />
  )
}