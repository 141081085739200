import { HTMLProps, useEffect, useRef } from 'react'

export interface VideoProps extends HTMLProps<HTMLVideoElement> {}

export function Video({ src, className, autoPlay, ...rest }: VideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!videoRef.current) return
    if (autoPlay) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }, [autoPlay])

  return <video
    ref={videoRef}
    src={src}
    playsInline
    muted
    className={className}
    {...rest} />
}
