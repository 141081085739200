import { format } from 'date-fns'
import { useRouter } from 'next/router'

import { Table, TableColum, Pagination } from '~/src/components/shared'
import { PostItemFragment, usePostsQuery, usePostCountQuery } from '~/src/generated/graphql'
import { usePagination } from '~/src/hooks'
import { Anchor } from '~/src/components/shared'
import { nonNullable, buildPostQuery } from '~/src/utils'

interface Row {
	id: string
	link: string
	no: string 
	title: string
	publishedAt: string
}

const columns: TableColum<Row>[] = [
  {
    key: 'no',
    label: '번호',
    width: '100px',
    align: 'center',
    mbStyle: 'mb-hidden'
  },
  {
    key: 'title',
    label: '제목',
    mbStyle: 'mb-full',
    render: (row: Row) => (
      <Anchor href={row.link}>{row.title}</Anchor>
    )
  },
  {
    key: 'publishedAt',
    label: '등록일',
    width: '100px'
  }
]

interface Props {
	festivalId?: string
	categoryId: string
	generateLink: (post: PostItemFragment) => string
	onPageChange: (page: number) => void
}

const PAGE_SIZE = 10


export function PostTable({ festivalId, categoryId, generateLink, onPageChange }: Props) {
  const router = useRouter()
  const page = Number(router?.query?.page || 1)
  const start = (page - 1) * PAGE_SIZE

  const { data, error, loading } = usePostsQuery({
    variables: {
      where: buildPostQuery({ festivalId, categoryId }),
      limit: PAGE_SIZE,
      start
    }
  })

  const { data: countData } = usePostCountQuery({
    variables: {
      where: buildPostQuery({ festivalId, categoryId })
    }
  })

  const posts = data?.posts ?? []
  const totalCount = countData?.postsConnection?.aggregate?.count ?? 0

  const handlePageChange = (_page: number) => {
    if (page === _page) return
    onPageChange?.(_page)
  }

  const { goTo, prev, next, hasNextPage, hasPrevPage, blockStartIndex, blockEndIndex, currentPage } = usePagination({ totalCount, initialPage: page, onPageChange: handlePageChange, pageSize: PAGE_SIZE })

  const items = posts.filter(nonNullable).map((row, idx) => ({
    id: row.id,
    link: `${generateLink(row)}`,
    no: (start + idx + 1).toString(),
    title: row.title,
    publishedAt: format(new Date(row.publishDate), 'yyyy.MM.dd')
  }))

  if (!loading && totalCount === 0) return null
  if (error) return null

  return (
    <>
      <Table<Row>
        uniqueKey="no"
        columns={columns}
        data={items}
        loading={loading}
        className="mb-20"
      />
      <Pagination 
        className="mb-20" 
        currentPage={currentPage} 
        onNext={next} 
        onPrev={prev} 
        goTo={goTo} 
        hasNextPage={hasNextPage} 
        hasPrevPage={hasPrevPage} 
        startIndex={blockStartIndex} 
        endIndex={blockEndIndex} 
      />
    </>
  )
}