
import { useReactiveVar } from '@apollo/client'
import React, { useState } from 'react'
import { VscListFlat } from 'react-icons/vsc'
import { HiOutlineSearch } from 'react-icons/hi'

import { showSearchLayerVar } from '~/src/libs/localCache'
import { Logo } from '~/src/components/shared/Logo'
import { Drawer } from '~/src/components/shared/Drawer'
import { SearchLayer } from '~/src/components/shared'
import { Menus } from './Menus'
import styles from './Header.module.scss'

export function Header() {
  const showSearchLayer = useReactiveVar(showSearchLayerVar) 
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false)

  return (
    <header
      className={styles.header}
    >
      <div className={styles.headerContainer}>
        <VscListFlat
          size={40}
          color='white'
          className='lg:hidden'
          onClick={() => setIsSideMenuOpen(true)}
        />
        <Logo />
        <HiOutlineSearch
          size={40}
          color='white'
          className='lg:hidden'
          onClick={() => showSearchLayerVar(true)}
        />
        {/* <div className='lg:hidden w-8 h-8' /> */}
        <Menus />
        <Drawer
          isOpen={isSideMenuOpen}
          onClose={() => setIsSideMenuOpen(false)} />
        <SearchLayer
          onClose={() => showSearchLayerVar(false)}
          isOpen={showSearchLayer} />
      </div>
    </header>
  )
}
