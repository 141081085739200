import { HTMLProps } from 'react'
import cx from 'classnames'

import styles from './Button.module.scss'
interface Props extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
  label?: string;
  variant: 'primary' | 'secondary' | 'info' | 'default';
  size: 'xs' | 'sm' | 'md' | 'lg';
  startIcon?: JSX.Element
  endIcon?: JSX.Element
}

export function Button({
  label,
  variant,
  type = 'button',
  size,
  children,
  className,
  startIcon,
  endIcon,
  ...rest
}: Props) {
  return (
    <button
      {...rest}
      type={type as any}
      className={cx(
        {
          'bg-primary-light': variant === 'primary',
          'bg-secondary-dark': variant === 'secondary',
          'bg-info-light': variant === 'info',
          'bg-gray-700': variant === 'default',
        },
        {
          'py-1 px-2': size === 'xs',
          'py-2 px-4': size === 'sm',
          'py-2 px-10': size === 'md',
          'py-2 px-12': size === 'lg',
        },
        'text-white',
        'relative',
        className
      )}>
      {
        startIcon && (
          <span className={styles.startIcon}>
            {startIcon}
          </span>
        )
      }
      {label || children}
      {
        endIcon && (
          <span className={styles.endIcon}>
            {endIcon}
          </span>
        )
      }
    </button>
  )
}
