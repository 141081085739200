import { MouseEvent, HTMLProps } from 'react'
import { useRouter } from 'next/router'

interface Props extends Omit<HTMLProps<HTMLAnchorElement>, 'href'> {
  children: any;
  isExternal?: boolean;
  href?: string | null;
}

export function Anchor({ href, children, isExternal, ...rest }: Props) {
  const router = useRouter()

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!href) return
    if (isExternal) {
      window.location.href = href
      return
    }
    router.push(href)

    e.preventDefault()
  }

  return (
    <a
      href={href ?? undefined}
      onClick={handleClick}
      {...rest}>
      {children}
    </a>
  )
}
