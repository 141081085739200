import { useRouter } from 'next/router'

import { useFestivalsContext } from '~/src/contexts'
import { Select } from '~/src/components/shared'

export function FilterSection() {
  const router = useRouter()
  const { year } = router.query
  const { years } = useFestivalsContext()
  const items = years.map(_year => ({ key: _year.toString(), value: _year.toString() }))
  const selected = year?.toString() || items?.[0]?.key

  const handleChange = (key: string) => {
    router.push({
      query: {
        year: key	
      }
    })	
  }

  if (!(items.length > 0)) return null

  return (
    <div className="text-center my-20">
      <span className="text-primary-light text-2xl mr-8 inline-block tracking-wide">FILTER BY</span>
      <Select
        items={items}
        onChange={handleChange}
        selected={selected} />
    </div>
  )
}