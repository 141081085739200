import { useState, useEffect } from 'react'
import cx from 'classnames'

import { TableLoading } from './TableLoading'
import styles from './Table.module.scss'

export interface TableColum<T> {
	key: keyof T
	label: string
	width?: string
	align?: 'left' | 'center' | 'right'
	render?: (row: T) => JSX.Element
	mbStyle?: MobileStyle
}

export interface Props<T> {
	columns: TableColum<T>[]
	className?: string
	uniqueKey: keyof T
	data: T[]
  loading?: boolean
}

type MobileStyle = 'mb-divider' | 'mb-full' | 'mb-hidden'

type Dict = { [key: string]: any }

export function Table<T extends Dict>({ className, columns, data, uniqueKey, loading }: Props<T>) {
  const [items, setItems] = useState<T[]>(data)

  useEffect(() => {
    if(!loading) setItems(data)
  }, [data, loading])

  return (
    <div className={cx(styles.dataTable, className)}>
      <div className={styles.colGroup}>
        {
          columns.map(col => (
            <div
              key={col.key.toString()}
              className={styles.col}
              style={{ width: col.width }} />
          ))
        }
      </div>
      <div className={cx(styles.tr, styles.thead)}>
        {
          columns.map(col => (
            <div
              key={col.key.toString()}
              className={styles.th}
              style={{ textAlign: col.align }}>
              {col.label}
            </div>
          ))
        }
      </div>
      {
        items.map((row, rowIdx) => (
          <div
            key={row[uniqueKey]}
            className={styles.tr}>
            {
              columns.map((col) => (
                <div
                  key={`${rowIdx}${col.key}`}
                  className={cx(styles.td, col.mbStyle)}
                  style={{ textAlign: col.align }}>
                  <div className={styles.tdInner}>
                    {col?.render ? col?.render(row): row[col.key]}
                  </div>
                </div>
              ))
            }
          </div>
        ))
      }
      {items.length > 0 && loading && <TableLoading />}
    </div>
  )
}