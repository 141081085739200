import { useState, useCallback, useEffect } from 'react'

interface UsePaginationParams {
	totalCount: number
	initialPage?: number
	pageBlockSize?: number
	pageSize?: number
	onPageChange?: (page: number) => void
}

interface PaginationInfo {
	currentPage: number
	totalPage: number
	hasNextPage: boolean
	hasPrevPage: boolean 
	prev: () => void
	next: () => void
	goTo: (page: number) => void
	startIndex: number
	endIndex: number
	blockStartIndex: number
	blockEndIndex: number
}

export function usePagination({ totalCount, pageBlockSize = 5, pageSize = 10, initialPage = 1, onPageChange }: UsePaginationParams): PaginationInfo {
	const [currentPage, setCurrentPage] = useState<number>(initialPage)

	const totalPage = Math.ceil(totalCount / pageSize)

	const currentBlock = Math.ceil(currentPage / pageBlockSize)
	const blockStartIndex = (currentBlock - 1) * pageBlockSize
	const blockEndIndex = blockStartIndex + pageBlockSize

	const startIndex = (currentPage - 1) * pageSize 
	const endIndex = startIndex + pageSize 

	const hasNextPage = currentPage < totalPage
	const hasPrevPage = currentPage > 1
	
	const prev = useCallback(() => {
		if(hasPrevPage) setCurrentPage(page => page - 1)
	}, [hasPrevPage])
	const next = useCallback(() => {
		if(hasNextPage) setCurrentPage(page => page + 1)
	}, [hasNextPage])
	const goTo = useCallback((page: number) => {
		setCurrentPage(page)
	}, [])

	useEffect(() => {
		onPageChange?.(currentPage)
	}, [currentPage])

	
	return {
		currentPage,
		totalPage,
		hasNextPage,
		hasPrevPage,
		startIndex,
		endIndex,
		blockStartIndex: blockStartIndex + 1,
		blockEndIndex: Math.min(blockEndIndex + 1, totalPage + 1),
		prev,
		next,
		goTo
	}
}