import { useRouter } from 'next/router'
import AspectRatio from 'react-aspect-ratio'
import { format } from 'date-fns'

import { useFestivalContext } from '~/src/contexts'
import { DEFAULT_DATE_FORMAT } from '~/src/const'
import { usePostConnection } from '~/src/hooks/post/usePostConnection'
import { Anchor, InfiniteScroll, Container } from '~/src/components/shared'
import { buildPostQuery, nonNullable } from '~/src/utils'

interface Props {
	categoryId: string
}

const PAGE_SIZE = 9

export function FestivalPostGrid({ categoryId }: Props) {
  const { asPath } = useRouter()
  const { data: festivalData } = useFestivalContext()
  const { id } = festivalData || {}

  const { posts, fetchMore, loading, error } = usePostConnection({
    where: buildPostQuery({ festivalId: id, categoryId }),
    start: 0,
    limit: PAGE_SIZE
  })

  if (loading || error) return null

  return (
    <Container>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 mb-20">
        {
          posts.filter(nonNullable).map(post => (
            <Anchor
              href={`${asPath}/${post.id}`}
              key={post.id}>
              <div className="bg-white p-2">
                {
                  post.coverImage && (
                    <div>
                      <AspectRatio ratio="1/1">
                        <img
                          src={post?.coverImage?.formats?.medium?.url}
                          className='cursor-pointer w-full h-full object-cover'
                        />
                      </AspectRatio>
                    </div>
                  )
                }
                <div className="py-2">
                  <h1 className="text-primary-light mb-2">{post.title}</h1>
                  <span className="text-gray-700">{format(new Date(post.publishDate), DEFAULT_DATE_FORMAT)}</span>
                </div>
              </div>
            </Anchor>
          ))
        }
      </div>
      <InfiniteScroll fetchMore={fetchMore} />
    </Container>
  )
}