import React from 'react'
import cx from 'classnames'

import { MENUS } from '~/src/const'
import { showSearchLayerVar } from '~/src/libs/localCache'
import { Anchor } from '~/src/components/shared/Anchor'
import { useCurrentMenu } from '~/src/hooks/useCurrentMenu'
import { useShopPageLinkQuery } from '~/src/generated/graphql'

import SearchIcon from '~/assets/icons/search.svg'

import styles from './Menu.module.scss'

export function Menus() {
  const menu = useCurrentMenu()

  const { data } = useShopPageLinkQuery()
  
  const shopLink = data?.shopPage?.link

  return (
    <ul className={styles.menu}>
      {MENUS.map(({ id, label, path }) => (
        <li
          key={id}
          className={cx(styles.menuItem, { [styles.active]: menu?.id === id })}>
          <Anchor href={id === 'shop' ? shopLink: path}>{label}</Anchor>
        </li>
      ))}
      <li className={styles.menuItem}>
        <SearchIcon
          className='w-6 h-6 inline-block cursor-pointer'
          onClick={() => showSearchLayerVar(true)}/>
      </li>
    </ul>
  )
}
