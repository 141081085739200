import cx from 'classnames'

export interface SectionHeaderProps {
  title: string | JSX.Element;
  textAlign?: 'left' | 'center' | 'right';
  description?: string;
  variant: 'primary' | 'default';
  className?: string;
}

export function SectionHeader({
  title,
  description,
  className,
  textAlign = 'center',
  variant = 'default',
}: SectionHeaderProps) {
  return (
    <div className={cx(className, `text-${textAlign}`)}>
      <h1
        className={cx(
          {
            'text-primary-light font-poppins text-5xl': variant === 'primary',
            'text-4xl': variant === 'default',
          },
          'color-black mb-4 font-bold',
        )}>
        {title}
      </h1>
      {description && <span className='text-2xl text-gray-800'>{description}</span>}
    </div>
  )
}
