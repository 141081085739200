import AspectRatio from 'react-aspect-ratio'
import { useFestivalContext } from '~/src/contexts'
import { FestivalPosition } from '~/src/generated/graphql'

import { PageBanner, Anchor } from '~/src/components/shared'
import { Banner } from '~/src/types/banner'
import { nonNullable, isEmpty } from '~/src/utils'

interface Props {
	position: FestivalPosition
}

const IMAGE_PATTERN = /^image/

export function FestivalBanner({ position }: Props) {
  const { data } = useFestivalContext()

  const banners = data?.banners
  const item = banners?.find(b => b?.position === position)

  const bannerItems = item?.banner?.filter(nonNullable) as Banner[]

  if (isEmpty(bannerItems)) return null

  if ([FestivalPosition.InfoBanner_1, FestivalPosition.InfoBanner_2, FestivalPosition.InfoBanner_3].includes(position)) {
    const banner = bannerItems.find((banner) => IMAGE_PATTERN.test(banner?.media?.mime))
    if(!banner) return null
    return (
      <AspectRatio ratio="1/1">
        <Anchor href={banner.link}>
          <img 
            src={banner?.media?.formats?.medium?.url}
            className="object-cover w-full h-full"
          />
        </Anchor>
      </AspectRatio>
    )
  }

  return (
    <PageBanner items={bannerItems} />
  )
}