import SwiperCore, { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import AspectRatio from 'react-aspect-ratio'


import { Banner } from '~/src/types/banner'
import { Anchor, Video, Image } from '~/src/components/shared'
import { Container } from '~/src/components/shared'
import { isEmpty } from '~/src/utils'

import styles from './PageBanner.module.scss'

const VIDEO_PATTERN = /^video/
const IMAGE_PATTERN = /^image/

SwiperCore.use([Navigation, Pagination])

interface Props {
  items: Banner[]
}

export function PageBanner({ items }: Props) {
  if (isEmpty(items)) return null

  if(items.length === 1) {
    const [item] = items
    const { media } = item
    return (
      <Container>
        <Media
          autoPlay
          formats={media.formats}
          url={media.url}
          mime={media.mime} />
      </Container>
    )
  }

  return (
    <Container>
      <div className='overflow-hidden'>
        <Swiper
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}>
          {items.map((banner) => (
            <SwiperSlide key={banner.id}>
              {({ isActive }) => (
                <AspectRatio
                  ratio='16/9'
                  className='w-full bg-gray-200'>
                  <Anchor
                    href={banner?.link}
                    className={styles.bannerItem}>
                    <Media
                      formats={banner.media.formats}
                      autoPlay={isActive}
                      url={banner.media.url}
                      mime={banner.media.mime} />
                  </Anchor>
                </AspectRatio>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  )
}

interface MediaProps {
  url: string;
  mime: string;
  autoPlay: boolean;
  formats: any;
}

function Media({ url, mime, autoPlay, formats }: MediaProps) {
  if (VIDEO_PATTERN.test(mime))
    return (
      <Video
        src={url}
        style={{ marginTop: -3 }}
        autoPlay={autoPlay}
        controls
        className='w-full h-full'
      />
    )

  if (IMAGE_PATTERN.test(mime))
    return (
      <Image
        src={url}
        loading="eager"
        formats={formats}
        layout='responsive'
        width={16}
        height={9}
        className='bg-gray-30'
      />
    )

  return null
}
