import { useRouter } from 'next/router'

import { MENUS } from '~/src/const'

export function useCurrentMenu() {
  const { pathname } = useRouter()

  const menu = MENUS.find((menu) => pathname.startsWith(menu.path))

  return menu
}
