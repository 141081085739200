import { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import { MdKeyboardArrowUp } from 'react-icons/md'
import { useClickOutside } from '~/src/hooks/useClickOutside'

import styles from './Select.module.scss'

export interface Option {
  key: string;
  value: string;
}

interface Props {
  onChange: (key: string) => void;
  items: Option[];
  selected: string;
  placeholder?: string;
}

export function Select({ onChange, items, selected, placeholder }: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>()
  const selectedItem = items.find(({ key }) => key === selected)

  const handleItemClick = (key: string) => {
    onChange(key)
    setIsOpen(false) 
  }

  useClickOutside({ ref, handler: () => setIsOpen(false) })

  return (
    <div className={styles.wrapper} ref={ref}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer">
        <div className={styles.label}>{selectedItem ? selectedItem.value : placeholder}</div>
        <span className={cx(styles.arrow, !isOpen ? styles.down: styles.up)}><MdKeyboardArrowUp /></span>
      </div>
      {
        isOpen && (
          <ul className={cx(styles.items)}>
            {items.map(({ key, value }) => (
              <li
                onClick={() => handleItemClick(key)}
                className={cx({ [styles.active]: key === selected })}
                key={key}>{value}</li>
            ))}
          </ul>
        )
      }
    </div>
  )
}
