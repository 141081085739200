import React, { useRef } from 'react'

import { Portal } from '~/src/components/shared'
import { useClickOutside } from '~/src/hooks'
import { SearchForm } from '~/src/components/shared'

import styles from './SearchLayer.module.scss'

interface Props {
	isOpen: boolean
	onClose: () => void
}

export function SearchLayer({ onClose, isOpen }: Props) {
  const ref = useRef<HTMLDivElement>(null)

  useClickOutside({
    ref,
    handler: onClose
  })

  if (!isOpen) return null

  return (
    <Portal>
      <div className={styles.layer}>
        <div className={styles.searchArea}>
          <div className={styles.searchContainer}>
            <div ref={ref}>
              <SearchForm />
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}