import cx from 'classnames'
import AspectRatio from 'react-aspect-ratio'
import { useRouter } from 'next/router'
import { format } from 'date-fns'

import { PostItemFragment, PostType } from '~/src/generated/graphql'
import { DEFAULT_DATE_FORMAT } from '~/src/const'
import { YoutubeEmbed } from '~/src/components/shared'
import { getYoutubeEmbedUrl, getYoutubeWatchId } from '~/src/utils/youtube'

import styles from './PostCard.module.scss'

export enum PostCoverType {
  Wide,
  Square
}

export enum PostCardViewType {
  Vertical,
  Horizontal,
}

interface Props {
  post: PostItemFragment;
  viewType?: PostCardViewType;
  coverType?: PostCoverType;
  className?: string;
}

const getViewTypeClass = (viewType: PostCardViewType) =>
  viewType === PostCardViewType.Horizontal ? styles.horizontal : styles.vertical

const getExternalYoutubeUrl = (youtubeUrl: string) =>
  `https://youtube.com/watch?v=${getYoutubeWatchId(youtubeUrl)}`

const getImageRatio = (coverType: PostCoverType) => 
  coverType === PostCoverType.Wide ? { width: 16, height: 9 }: { width: 1, height: 1 }

const getDetailUrl = (pathname: string) => {
  if(['/mintplus', '/originals'].includes(pathname)) return pathname
  return '/posts'
}

export function PostCard({ post, className, viewType = PostCardViewType.Vertical, coverType = PostCoverType.Wide }: Props) {
  const router = useRouter()
  const { id, coverImage, publishDate, description, title, youtubeUrl, type } = post || {}

  const handleNavigate = () => {
    if (youtubeUrl) {
      window.location.href = getExternalYoutubeUrl(youtubeUrl)
      return
    }
    router.push(`${getDetailUrl(router.pathname)}/${id}`)
  }

  const ratioObj = getImageRatio(coverType)
  const youtubeWatchId = youtubeUrl ? getYoutubeWatchId(youtubeUrl): null

  return (
    <article className={cx(styles.post, className, getViewTypeClass(viewType))}>
      <div
        className={styles.postThumbnail}
      >
        {youtubeWatchId && type === PostType.YoutubeExternal ? (
          <YoutubeEmbed
            url={getYoutubeEmbedUrl(youtubeWatchId)}
            ratio={`${ratioObj.width}/${ratioObj.height}`}/>
        ) : coverImage?.url && (
          <AspectRatio ratio={`${ratioObj.width}/${ratioObj.height}`}>
            <img
              src={coverImage?.formats?.medium?.url ?? ''}
              onClick={handleNavigate}
              className='cursor-pointer'
            />
          </AspectRatio>
        )}
      </div>
      <div
        onClick={handleNavigate}
        className={cx(styles.content, 'cursor-pointer')}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        <span className={styles.date}>
          {format(new Date(publishDate), DEFAULT_DATE_FORMAT)}
        </span>
      </div>
    </article>
  )
}
