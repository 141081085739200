import { useRouter } from 'next/router'

import { ProgramView, NormalView } from '~/src/components/post'
import { BaseLayout } from '~/src/components/shared/layout'
import { usePostQuery, PostType } from '~/src/generated/graphql'

export function PostDetailPage() {
  const { query, asPath } = useRouter()
  const { postId, festivalId } = query || {}
  const { data, loading, error } = usePostQuery({
    variables: {
      id: postId as string,
    },
    skip: !Boolean(postId),
  })

  const post = data?.post
  const postCategoryId = post?.category?.categoryId

  const parentPath = asPath.slice(0, asPath.lastIndexOf('/'))
  const getListUrl = () => festivalId ? parentPath: `/categories/${postCategoryId}`
  const listUrl = getListUrl()


  if (loading || error) return null
  if (!postId || !post) return null

  return (
    <BaseLayout>
      {post.type === PostType.Normal ? (
        <NormalView
          festivalId={festivalId as string}
          listUrl={listUrl}
          generateLink={postId => `${parentPath}/${postId}`} />
      ): (
        <ProgramView post={post} />
      )}
    </BaseLayout>
  )
}
