import { Loading } from '~/src/components/shared/Loading'

import styles from './TableLoading.module.scss'

export function TableLoading() {

  return (
    <div className={styles.loading}>
      <div className={styles.content}>
        <Loading size={50}/>
        <span className={styles.text}>Loading...</span>
      </div>
    </div>
  )
}