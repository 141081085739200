import { parse } from 'querystring'

export function getYoutubeWatchId(youtubeUrl: string | null): string | null {
  let url
  if(!youtubeUrl) return null
  try {
    url = new URL(youtubeUrl)

    const params = parse(url.search.slice(1))
    if (url.pathname.startsWith('/watch')) return params?.v as string
    if (url.pathname.startsWith('/embed')) {
      const id = url.pathname.split('/embed/')?.[1]
      return id
    } 
  } catch (e) {
  }
  return null
}

export function getYoutubeEmbedUrl(watchId: string) {
  return `https://youtube.com/embed/${watchId}`
}