import cx from 'classnames'
import { cleanupHtml } from '~/src/utils'

import styles from './PostContent.module.scss'

interface Props {
  content: string;
  className?: string;
}

export function PostContent({ content, className }: Props) {
  return (
    <div
      className={cx(className, styles.content)}
      dangerouslySetInnerHTML={{ __html: cleanupHtml(content) }}
    />
  )
}
